import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import '../Hero/hero.css'
import { data } from '../../data.js';

function Hero() {
  return (
    <div className='hero'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        {data.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <div className="slider-text-div">
                <h2 className='slider-title'>{item.title}</h2>
                <p className='slider-description'>{item.description}</p>
              </div>
              <img src={item.image} alt="gallery" />
            </SwiperSlide>
          )
        })}
        <div className="autoplay-progress" slot="container-end">
        </div>
      </Swiper>
    </div>
  );
}

export default Hero