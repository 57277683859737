import React from 'react'
import About from '../Components/About/About'
import Booking from '../Components/Booking/Booking'
import Hero from '../Components/Hero/Hero'
// import Map from '../Components/Map/Map'
import Services from '../Components/Services/Services'
import Testimonial from '../Components/Testimonial/Testimonial'
import Working from '../Components/Working/Working'

function Home() {
  return (
    <div>
        <Hero/>
        <About/>
        <Services/>
        <Booking/>
        <Working/>
        <Testimonial/>
    </div>
  )
}

export default Home