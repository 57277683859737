import IMG1 from './assets/banner03.png'
import IMG2 from './assets/banner.png'
import IMG3 from './assets/banner02.png'
import IMG4 from './assets/banner05.png'
import IMG5 from './assets/banner06.png'

const data = [
    {
        id: 1,
        image: IMG1,
        title: "Physiotherapy",
        description: 'Physiotherapists are health care professionals specifically educated to deal with the musculoskeletal components of the human body. Because of their knowledge base and clinical skills, a physiotherapist is an essential part of the rehabilitation process for people with muscle, joint, and nerve pain or dysfunction.',
        live: '/physiotherapy'
    },
    {
        id: 2,
        image: IMG2,
        title: "Massage Therapy",
        description: 'Massage therapy consists of hands-on manipulation of the soft tissues of the body; specifically, the muscles, connective tissue, tendons, ligaments and joints. Massage treatment has a therapeutic effect and optimizes health and well-being by acting on the muscular, nervous, and circulatory systems.',
        live: '/massage'
    },
    {
        id: 3,
        image: IMG3,
        title: "Myofascial Release",
        description: 'The fascial system covers every muscle, artery, nerve, bone, and organ in the body. It is a densely woven connective tissue that can be compared to a fishnet or spider’s web, connecting each part of the body from head to toe without interruption.',
        live: '/myofascial'
    },
    {
        id: 4,
        image: IMG4,
        title: "Orthotics",
        description: 'Designed and custom made for you by a qualified technician. A bio-mechanical gait analysis will first be performed by a certified pedorthist followed by a casting or 3D image of the foot.',
        live: '/orthotics'
    },
    {
        id: 5,
        image: IMG5,
        title: "Pelvic Physiotherapy",
        description: 'Kegel exercises are not always indicated for pelvic floor problems. In fact, sometimes they do more harm than good, and often they are not performed correctly by patients.',
        live: '/pelvic'
    },
];
//   export default data;

const physioData = [
    {
        id: 1,
        title: "ARTHRITIS",
    },
    {
        id: 2,
        title: "SPORTS INJURIES",
    },
    {
        id: 3,
        title: "CARPEL TUNNEL SYNDROME",
    },
    {
        id: 4,
        title: "CONCUSSION",
    },
    {
        id: 5,
        title: "DIZZINESS, VERTIGO AND IMBALANCE",
    },
    {
        id: 6,
        title: " FROZEN SHOULDER",
    },
    {
        id: 7,
        title: "TENNIS ELBOW/GOLFERS ELBOW",
    },
    {
        id: 8,
        title: "HEADACHE",
    },
    {
        id: 9,
        title: "HEEL PAIN/PLANTAR FASCIITIS",
    },
    {
        id: 10,
        title: "LOW BACK PAIN",
    },
    {
        id: 11,
        title: "MOTOR VEHICLE ACCIDENTS",
    },
    {
        id: 12,
        title: "NECK PAIN",
    },
    {
        id: 13,
        title: "PAEDIATRIC CONDITIONS",
    },
    {
        id: 14,
        title: "PELVIC FLOOR CONDITIONS",
    },
    {
        id: 15,
        title: "POST SURGERY",
    },
    {
        id: 16,
        title: "REPETITIVE STRAIN",
    },
    {
        id: 17,
        title: "ROTATOR CUFF INJURY",
    },
    {
        id: 18,
        title: "SCIATICA",
    },
    {
        id: 19,
        title: "TENDINITIS",
    },
    {
        id: 20,
        title: "SPRAINS AND STRAINS",
    },
    {
        id: 21,
        title: "TMJ DYSFUNCTIONS",
    },
    {
        id: 22,
        title: "WHIPLASH",
    },
    {
        id: 23,
        title: "PAIN IN JOINTS",
    },
];

const reviewData = [
    {
        id: 1,
        name: "Michael L",
        review: "As a long term patient of 4.5 years working with Prime Care Physio Jency Ulahannan P.T., and her Staff, have been professional, courteous and attentive to all my needs.Jency makes you feel comfortable, relaxed, and engaged in Conversation.she will work with you in pain management, and keep constant contact with documents to your General  Practitioner, surgeon, or Government Affiliate.I would recommend Prime Care Physiotherapy and Jency a well educated professional, with a great bedside manner, who will manage your pain journey to the end of treatment."
    },
    {
        id: 2,
        name: "Tahrin Mahmood",
        review: "Jency and her staff (Parul) are amazingly nice, encouraging and very empathetic.I heard about them through facebook after going to Blueberry and not finding any relief 6 months post partum.The fact that Jency does myofacial release helps so much - she is hands on and that helps a lot! Definitely recommended."
    },
    {
        id: 3,
        name: "Yasmeen Shalaby",
        review: "I have been having pelvic issues for the past 13 years.After only 2 sessions with Jency I experienced a huge difference.My symptoms have decreased drastically.I would recommend Jency to any woman having pelvic issues such as endometriosis, vaginismus and many others.She is a hands on therapist...i love her!!!!"
    },
    {
        id: 4,
        name: "Maggie Carpenter",
        review: "After suffering with back and left leg pain and numbness for months I was referred to Jency and was not disappointed. I have regained the strength in my left leg and can put weight on it again without fear of falling.She has also educated me on how to avoid things that have aggravated my back in the past and has shown exercises I can do at home to strengthen the muscles in my back and legs.I have my life back again and am looking forward to golfing again this summer!"
    },
    {
        id: 5,
        name: "G. Wilkins",
        review: "As a family we have been treated by Jency and her staff since 2014.  Treatments ranged from pre and post orthopaedic surgeries, recovery from debilitating illness, sprains and breaks, and vertigo.We are more than pleased with her knowledge, skills and professionalism.We highly recommend Prime Care Physiotherapy!"
    },
    {
        id: 6,
        name: "Anne Collins",
        review: "Many times I have had serious back and leg pain, barely able to walk.Jency uses some of her many skills and sends me home feeling better.Next day I feel well enough for some moderate activity.Jency knows how to respond to and treat the many chronic issues that arise from Osteoarthritis, Lupus, and Scleroderma.My GP told me that Jency knew what she is doing and she does!Jency is kind and compassionate as are her staff.They are consistently pleasant and are helpful in dealing with special needs and insurance companies."
    },
    {
        id: 7,
        name: "Janice Ziebarth",
        review: "I have dealt with Jency Ulahannan and Prime  Care Physiotherapy for over 8 years.They are outstanding! They have given me complete mobility.Jency’s knowledge and techniques are vast.I will not go anywhere else !"
    },
    {
        id: 8,
        name: "Susan Routliffe",
        review: "Jency has an excellent understanding of TMJ. Her care is focused to your individual need.She is knowledgeable, kind, and professional.I highly recommend her service."
    },
    {
        id: 9,
        name: "Jonathan Carline",
        review: "I was referred to them by a friend and have not been disappointed.Jency and her team are wonderful and very professional. Everyone really knows what they are doing and their personal touch goes a long way in making you feel welcomed and comfortable.I would recommend them without hesitation."
    },
    {
        id: 10,
        name: "Shuai Ge",
        review: "I been getting treatment from Jency for serval weeks now, I can say without a doubt she is an excellent physiotherapist. Her method is very thorough and she clearly takes great pride in her work."
    },
    {
        id: 11,
        name: "Tom Symonds",
        review: "Excellent, attentive  treatment received by Jency Ulahannan and her staff.Expecting much earlier, than expected, return to golf after rotator cuff tendon tears"
    },
    {
        id: 12,
        name: "Jane Steinberg",
        review: "Excellent service and very professional. She had our daughter back in great shape after a sprained ankle in two weeks and she was fantastic."
    },
    {
        id: 13,
        name: "Todd Brownhill",
        review: "Great experience with tendonitis treatment."
    }

];

//   export default physioData;
export { data, physioData, reviewData };