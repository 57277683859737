import React from 'react'
import '../Footer/footer.css'

function Footer() {
  return (
    <footer>
      <small>&#169; 2023.  All Rights Reserved by www.primecarerehab.ca </small>
    </footer>
  )
}

export default Footer