import React from 'react'
import HERO from '../assets/myofascial.png'
import IMG from '../assets/banner02.png'
import './myofascial.css'

function Myofascial() {
  return (
    <>
      <img className='hero-img' src={HERO} alt="myofascial release" />
      <div className="container myo-container">
        <h2>Myofascial Release</h2>
        <section className="mayo-intro">
          <img className='text-image text-image-mayo' src={IMG} alt="mayofascial release" />
          <div className="text-div">
          <h3>What is Myofascial release?</h3>
          <p>Myofascial release is a whole body, hands on approach focusing on the fascial systems. Fascial restrictions can be caused by physical trauma, inflammatory or infectious process or structural in balance, dental malocclusion, osseous restriction, leg length discrepancy and pelvic rotation, etc. Having restrictions on the fascial structures, can cause symptoms locally or distant from the area of injury. All the appropriate localized treatments will produce poor or temporary result because the imbalance and excessive pressure from the Myofascial tightness remain untreated. The goal of Myofascial release is to remove fascial restrictions and restore the body's equilibrium. When the structures returns to a balance state, it is realigned with gravity. When these aims have been accomplished, the body's inherent ability to self-correct returns, thus restoring optimum function and performance and least energy expenditure.
            Fascia is a tough connective tissue that spreads throughout the body in a three dimensional web. Surrounds, infuses with every muscle, bone, nerve, blood vessel, organ and cells of the body. The fascia serves major purpose that permits the body to retain its shape and maintains the vital organs in correct position.
            The functions are varied and complex. The functional biomechanical efficient movement depends on intact properly distributed fascia. Loose fascia permits movement between adjacent structures. The loose tissues contains a fluid that serves as a transport medium for cellular elements of other tissues, blood, and lymph. In this manner fascia also fills a nutritive function. Structurally the planes is connective tissue allow passage of infections and inflammatory process; also the connective tissues neutralizer or detoxifies toxins; finally its fibroblastic quality permit fascia to assist in healing injuries by depositing collagenous fibers. (Information adapted from John Barnes notes on “myofascial release the search for excellence”)</p>

          </div>
        </section>

      </div>
    </>
  )
}

export default Myofascial