import React from 'react'
import './testimonial.css'
import {reviewData} from '../../data.js'

import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';


function Testimonial() {

  return (
    <section id="testimonial">
        <h4>Review from Clients</h4>
        <h2>Reviews</h2>
        <Swiper className="container container-testimonials"

            // install Swiper modules
            modules={ [Navigation, Pagination] }
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}>

            {reviewData.map((item)=>{
                return(
                <SwiperSlide key={item.id} className="testimonial">
                    <h3 className='client-name'>{item.name}</h3>
                    <Link to={'https://www.google.com/maps/place/Prime+Care+Physiotherapy+Clinic/@43.2281807,-79.9784516,17z/data=!3m1!5s0x882c85b02e315a2b:0x1d632d3e5fe132f8!4m8!3m7!1s0x882c84693d98d85d:0x9117c0cdd1485f11!8m2!3d43.2281807!4d-79.9758767!9m1!1b1!16s%2Fg%2F1yghb610j?hl=en-US&entry=ttu'} target='_blank'>
                      <h4 className="client-review"><i>"{item.review}"</i></h4>
                    </Link>
                </SwiperSlide>)
        })}
        </Swiper>
    </section>        
  )
}

export default Testimonial