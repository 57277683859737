
import './App.css';

import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Massage from './Pages/Massage';
import Physiotherapy from './Pages/Physiotherapy';
import Myofascial from './Pages/Myofascial';
import Pelvic from './Pages/Pelvic';
import Orthotics from './Pages/Orthotics';
import Contact from './Components/Contact/Contact';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/physiotherapy' element={<Physiotherapy/>}></Route>
          <Route path='/massage' element={<Massage/>}></Route>
          <Route path='/myofascial' element={<Myofascial/>}></Route>
          <Route path='/orthotics' element={<Orthotics/>}></Route>
          <Route path='/pelvic' element={<Pelvic/>}></Route>
        </Routes>
        <Contact />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
