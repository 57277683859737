import React from 'react'
import '../Services/services.css'
import {data} from '../../data.js'
import {HiChevronDoubleRight} from 'react-icons/hi'
import { Link } from 'react-router-dom'

function Services() {
  return (
    
    <section className='container services-container'>
      {data.map((item)=>{
        return(
          <div key={item.id} className="data-div">
            <img className='services-image' src={item.image} alt="" />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <Link className='read-more-link' to={item.live}>Read More <HiChevronDoubleRight /></Link>
          </div>
        )
      })}
    </section>
  )
}

export default Services