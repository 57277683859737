import React from 'react'
import HERO from '../assets/physiotherapy.png'
import IMG from '../assets/banner03.png'
import { physioData } from '../data.js'
import { BiCheckCircle } from 'react-icons/bi'
import {AiFillCheckSquare} from 'react-icons/ai'
import './physiotherapy.css'

export default function Physiotherapy() {
  return (
    <>
      <img className='hero-img' src={HERO} alt="physiotherapy" />
      <div className="container container-physiotherapy">
        <h2>Physiotherapy</h2>
        <section className="phyiotherapy-intro">
          <img className='text-image text-image-physio' src={IMG} alt="physiotherapy" />
          <p>Physiotherapy is a health care profession assists to restore, maintain and maximize the strength, function, movement and overall well-being.
            Physiotherapists have knowledge of how the body works and specialized hands-on clinical skills to assess, diagnose and treat various injuries and illness. Physiotherapy includes rehabilitation, prevention of injury and promotion of health and fitness.</p>
        </section>
        <section>
        <h3>PHYSIOTHERAPY CAN HELP</h3>
        <ul>
          <li className='physiotherapy-list'><AiFillCheckSquare className='list-icon'/><p>Become stronger and flexible</p></li>
          <li className='physiotherapy-list'><AiFillCheckSquare className='list-icon'/><p>Increase mobility</p></li>
          <li className='physiotherapy-list'><AiFillCheckSquare className='list-icon'/><p>Reduce pain</p></li>
          <li className='physiotherapy-list'><AiFillCheckSquare className='list-icon'/><p>Stay active</p></li>
          <li className='physiotherapy-list'><AiFillCheckSquare className='list-icon'/><p>Prevent injury</p></li>
        </ul>

        </section>
        <h3>Some of the conditions that can be treated by physiotherapy</h3>
        <section className="list-container">
          {physioData.map((item) => {
            return (
              <div key={item.id} className="physio-list">
                <BiCheckCircle className='check' />
                <p className='list-data'>{item.title}</p>
              </div>

            )
          })}
        </section>
        <h3>What to expect during your visit at prime care :</h3>
        <p>Every patient will have a detailed assessment and discuss the findings, we will create a treatment plan to achieve client-centered goals. We believe in treating every patient with a hands-on physiotherapy approach.</p>
      </div>

    </>
  )
}
