import React from 'react'
import IMAGE1 from '../../assets/welcome.png'

import '../About/about.css'

function About() {
  return (
    <div className='container about-container'>
      <section className="welcome-text-div">
        <h1>Welcome To</h1>
        <h3>Prime Care Physiotherapy Clinic</h3>
        <p>Prime Care Physiotherapy Clinic is a multidisciplinary clinic serving Ancaster, Dundas, Hamilton and surrounding areas since 2013. We are focused on personalized, hands-on, high quality care.</p>
        <h3 className='sub-text'>Services Offered:</h3>
        <p>Physiotherapy, Registered Massage Therapy, Myofascial Release, Orthotics, In-Home Physiotherapy (Home Care), Assistive Devices Program (ADP), WSIB & motor vehicle injury claims and Pelvic Physiotherapy.</p>
      </section>
      <img className='about-image' src={IMAGE1} alt="weight training" />
    </div>
  )
}

export default About;