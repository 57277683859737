import React from 'react'
import '../Booking/booking.css'

function Booking() {
  return (
    <section className='container booking-container'>
        <h2 className='booking-h2'>Book An Appointment</h2>
        <a className='btn btn-primary a-btn' href='https://primecarerehab.janeapp.com/' rel="noreferrer" target="_blank" >Book Appointment</a>
        <h3>Call Us +1(905)648-5554</h3>
    </section>
  )
}
export default Booking