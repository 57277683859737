import React from 'react'
import HERO from '../assets/pelvic.png'
import IMG from '../assets/banner06.png'
import IMG1 from '../assets/pelvic02.png'
import { RiCheckboxCircleFill } from 'react-icons/ri'
import './pelvic.css'


function Pelvic() {
  return (

    <>
      <img className='hero-img' src={HERO} alt="pelvic physiotherapy" />
      <div className="container pelvic-container">
        <h2>Pelvic Physiotherapy</h2>
        <section className="pelvic-intro">
          <img className='text-image text-image-pelvic ' src={IMG} alt="mayofascial release" />
          <div className="text-div">
            <h3>Did you know?</h3>
            <p>42% of women have urinary incontinence during pregnancy ( KariBo, Siv Morkved 2000) 1 in 3 women have urinary incontinence and 1 in 10 have fecal incontinence after child birth (Hay - Smith et al.2008) Pelvic Health concerns are very common and very seldom talked or addressed. Many people suffer without realizing most of these issues can be treated effectively. Pelvic floor physiotherapy is not only Kegel exercises. Incontinence and pelvic pain can be caused by hypo tonicity (weak muscles) or hypertonicity (tight pelvic floor muscles).
              Kegel exercises are not always indicated for pelvic floor problems sometimes they do more harm than good. The pelvic floor muscles give the support for the organs in the pelvic floor. In a normal situation these muscles should be able to contract and relax to maintain normal bladder and bowl function, when these muscles cannot function well due to its (hypo or hyper tonicity) or abnormal tone people presents with many symptoms such as urinary urgency, frequency, hesitancy, painful urination, unable to empty completely, constipation, pain the low back and pelvic area, pain during or after intercourse.
              Pelvic floor dysfunction can be evaluated and treated by Physiotherapist using internal or external manual technique.</p>
          </div>
        </section>
        <section className="pelvic-div-bottom">
          <div className="pelvic-ul">

          <h3>Following are some of the symptoms and conditions can be treated
          </h3>
          <ul>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>
                  Prenatal or post-natal -Pain in pubic bone, groin, back, pelvis, buttocks, legs
                </p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>
                  Leaking urine, gas, stool when laugh/ sneeze/ cough/ walking/ running etc.                </p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>
                  Abdominal diastasis or bulging/ tenting of abdomen during any exercises</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Pain with intercourse</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Vaginal varicose veins</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Scaring from C-section/ episiotomy/tear</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Problem emptying the bladder completely</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Pressure or heaviness or bulging in vagina/rectum.</p>
              </div>
            </li>
            <li>
              <div className="li-div">
                <RiCheckboxCircleFill className='bullet' />
                <p>Conditions such as vulvodynia, Endometriosis, Menstrual problems. Coccygeal pain etc.</p>
              </div>
            </li>
          </ul>
          </div>
          <img className='pelvic-image-2' src={IMG1} alt="depressed lady" />
        </section>

      </div>
    </>
  )
}

export default Pelvic