import React from 'react'
import HERO from '../assets/orthotics.png'
import IMG from '../assets/orthotics01.png'
import IMG1 from '../assets/compression-stockings.png'
import IMG2 from '../assets/athletes.png'
import IMG3 from '../assets/collar-workers.png'
import IMG4 from '../assets/travelers.png'
import IMG5 from '../assets/elderly.png'
import IMG6 from '../assets/Patients.png'
import { RiCheckboxCircleFill } from 'react-icons/ri'
import './orthotics.css'

function Orthotics() {
  return (
    <>
      <img className='hero-img' src={HERO} alt="orthotics" />
      <div className="container ortho-container">
        <h2>Orthotics</h2>
        <div className="ortho-container-1">
          <section className="text-div-1">
            <h3>CUSTOM ORTHOTICS</h3>
            <p>Designed and custom made for you by a qualified technician. A bio-mechanical gait analysis will first be performed by a certified pedorthist followed by a casting or 3D image of the foot.</p>
            <h3>SPECIAL CONSIDERATIONS</h3>
            <ul>
              <li>
                <div className="li-div">
                  <RiCheckboxCircleFill className='bullet' />
                  <p>
                    Follow up appointments are 4 weeks after fitting, then every year after to ensure optimal functionality.
                  </p>
                </div>
              </li>
              <li>
                <div className="li-div">
                  <RiCheckboxCircleFill className='bullet' />
                  <p>
                    Dealing directly with our pedorthist allows for quicker response time and faster solutions
                  </p>
                </div>
              </li>
              <li>
                <div className="li-div">
                  <RiCheckboxCircleFill className='bullet' />
                  <p>
                    The use of 3D casting ensures the product will truly be customized exactly to your foot
                  </p>
                </div>
              </li>
            </ul>
          </section>
          <img className='text-image-1 text-image-orthotics' src={IMG} alt="orthotics" />
        </div>
            <h3>CUSTOM OR OFF-THE-SHELF BRACING</h3>
            <p>We will outfit you with the correct product to best suit your needs and ensure an optimal fit. Whether necessary for stabilization, injury prevention or pain relief we can provide the best solution at the appropriate price point.</p>
        <section className="ortho-container-2">
          <img className='text-image-2' src={IMG1} alt="orthotics" />
          <div className="text-div-2">
            <h3>BRACING WITH SELAMAT</h3>
            <ul>
              <li>
                <div className="li-div">
                  <RiCheckboxCircleFill className='bullet' />
                  <p>
                    Custom models are made to fit through casting or stringent measurements by a trained professional
                  </p>
                </div>
              </li>
              <li>
                <div className="li-div">
                  <RiCheckboxCircleFill className='bullet' />
                  <p>
                    We stand behind our products and will help with any issues should they arise
                  </p>
                </div>
              </li>
            </ul>
            <h3>COMPRESSION STOCKINGS</h3>
            <p>Standing all day long, working long hours, your blood can pool to your ankles leaving them swollen and sore. Compression stockings ensure optimal blood flow and circulation to tired achy legs.</p>
          </div>
        </section>
        <div className="ortho-container-3">
          <h3>BENEFACTORS</h3>
          <div className="image-div">
            <div className="image-row">
              <img className='image-item' src={IMG2} alt="athletes" />
              <p>Athletes</p>
            </div>
            <div className="image-row">
              <img className='image-item' src={IMG3} alt="workers" />
              <p>Working Population</p>
            </div>
            <div className="image-row">
              <img className='image-item' src={IMG4} alt="traveler" />
              <p>Travelers</p>
            </div>
            <div className="image-row">
              <img className='image-item' src={IMG5} alt="elderly" />
              <p>Elderly</p>
            </div>
            <div className="image-row">
              <img className='image-item' src={IMG6} alt="patient" />
              <p>Patients with circulatory concerns</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Orthotics