import React from 'react'
import { WiTime5, WiTime7, WiTime2 } from 'react-icons/wi'
import '../Working/working.css'

function Working() {
    return (
        <section className='container working-container'>
            <h2>Working Hours</h2>
            <div className="hours">
                <div className="hours-text-div">
                    <WiTime7 className='hours-icon'/>
                    <div className="hours-text">
                        <h3>Mon, Wed, Thu</h3>
                        <h3>8am - 7pm</h3>
                    </div>
                </div>

                <div className="hours-text-div">
                    <WiTime5 className='hours-icon'/>
                    <div className="hours-text">
                        <h3>Tue</h3>
                        <h3>8am - 5pm</h3>
                    </div>
                </div>


                <div className="hours-text-div">
                    <WiTime2 className='hours-icon'/>
                    <div className="hours-text">
                        <h3>Fri</h3>
                        <h3>8am - 2pm</h3>
                    </div>
                </div>

                <div className="hours-text-div">
                    <WiTime7 className='hours-icon'/>
                    <div className="hours-text">
                        <h3>Sat, Sun</h3>
                        <h3>Closed</h3>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default Working