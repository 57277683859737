import React from 'react'
import HERO from '../assets/massage.png'
import IMG1 from '../assets/banner.png'
import ICON1 from '../assets/icon.png'
import ICON2 from '../assets/icon-01.png'
import ICON3 from '../assets/icon-02.png'
import ICON4 from '../assets/icon-03.png'
import IMG2 from '../assets/01.png'
import IMG3 from '../assets/02.png'
import './massage.css'

function Massage() {
  return (
    <>
      <img className='hero-img' src={HERO} alt="massage" />
      <section className='container container-massage'>
        <h2>Massage Therapy</h2>
        <section className='img-text-div'>
          <img className='text-image text-image-massage' src={IMG1} alt="massage" />
          <p>Massage treatment has a therapeutic effect and optimizes health and well-being by acting on the muscular, nervous, and circulatory systems.
            Zuzana Beyer is a Registered Massage Therapist with over 10 years experience, specializing in Integrated Orthopedic Massage, Complicated conditions, Sports injuries and Athletic performance. She is also a teaching assistant for James Waslaski. Zuzana has a Personal Trainer experience, her treatments incorporate Swedish massage techniques to elevate pain and reduce everyday stress.
            Massage therapy consists of hands-on manipulation of the soft tissues of the body; specifically, the muscles, connective tissue, tendons, ligaments and joints.</p>
        </section>
        <h3>Our Advantages</h3>
        <section className='massage-bottom-section'>

          <div className="icons-container">
            <div className='massage-icon-div'>
              <img src={ICON2} alt="massage" />
              <h4>High quality care</h4>
              <p>Well qualified physiotherapists</p>
            </div>

            <div className='massage-icon-div'>
              <img src={ICON4} alt="massage" />
              <h4>Massage Therapy</h4>
              <p>Massage Therapy is being positioned as an everyday need for everyone</p>
            </div>

            <div className='massage-icon-div'>
              <img src={ICON3} alt="massage" />
              <h4>Intuitive methods</h4>
              <p>Focus on manual and manipulative therapies</p>
            </div>

            <div className='massage-icon-div'>
              <img src={ICON1} alt="massage" />
              <h4>Preventive measures</h4>
              <p>Going beyond treatment to take precautions for the future</p>
            </div>
          </div>
          <div className="img-div">
            <img className='img-massage-last' src={IMG3} alt="massage" />
            <img className='img-massage-last' src={IMG2} alt="massage" />
          </div>
        </section>
      </section>
    </>
  )
}

export default Massage