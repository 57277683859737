import React, { useState } from 'react'
import '../Header/header.css'
import LOGO from '../../assets/logo_xs.png'
import { Link, NavLink } from 'react-router-dom'
import { RxHamburgerMenu, RxCross1 } from 'react-icons/rx'

function Header() {

  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(!clicked)
  }

  return (
    <header>
      <Link to={'/'}>
        <img className='logo' src={LOGO} alt="logo" />
      </Link>
      <nav>
        <ul id='navbar' className={clicked ? "#navbar" : "#navbar active"}>
          <li><NavLink className="navLink" onClick={handleClick} style={({ isActive }) => ({ color: isActive ? '#f58220' : '#333' })} to={'/physiotherapy'}>Physiotherapy</NavLink></li>
          <li><NavLink className="navLink" onClick={handleClick} style={({ isActive }) => ({ color: isActive ? '#f58220' : '#333' })} to={'/massage'}>Massage Therapy</NavLink></li>
          <li><NavLink className="navLink" onClick={handleClick} style={({ isActive }) => ({ color: isActive ? '#f58220' : '#333' })} to={'/myofascial'}>Myofascial Release</NavLink></li>
          <li><NavLink className="navLink" onClick={handleClick} style={({ isActive }) => ({ color: isActive ? '#f58220' : '#333' })} to={'/orthotics'}>Orthotics</NavLink></li>
          <li><NavLink className="navLink" onClick={handleClick} style={({ isActive }) => ({ color: isActive ? '#f58220' : '#333' })} to={'/pelvic'}>Pelvic Physiotherapy</NavLink></li>
          <li><a className='contact-nav' href="#contact" onClick={handleClick} >Contact Us</a></li>
        </ul>

      </nav>
      <a href='https://primecarerehab.janeapp.com/' rel="noreferrer" target="_blank" className='btn btn-primary a-btn book-now'>Book Appointment</a>
      {clicked ? <RxCross1 id='mobile' onClick={handleClick} /> : <RxHamburgerMenu id='mobile' onClick={handleClick} />}
    </header>
  )
}

export default Header