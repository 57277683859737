import React, { useRef } from 'react';
import { AiOutlineMail, AiFillInstagram, AiOutlinePhone } from 'react-icons/ai'
import { MdOutlineLocationOn } from 'react-icons/md'
import { FaFacebookF } from 'react-icons/fa'
import emailjs from '@emailjs/browser';
import './contact.css'

function Contact() {

  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_n0ie7tj', 'template_hodddkf', form.current, 'IbeHhZvjyckjAbnUj')

      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()

  };

  return (
    <>
      <h4>Get In Touch</h4>
      <h2>Contact Us</h2>
      <section id="contact" className="container contact_container">

        {/* <div className="container contact_container"> */}
        <div className="contact_options">
          <div className="contact_option-item">
            <a href="https://maps.google.com/maps/dir//primecare+physiotherapy+hamilton/@43.2281593,-80.0459165,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x882c84693d98d85d:0x9117c0cdd1485f11!2m2!1d-79.9758767!2d43.2281807" rel="noreferrer" target='_blank'>
              <MdOutlineLocationOn className='contact_option-icon' />
              <h4 className='address'>Prime Care Physiotherapy Clinic<br />
                385 Wilson Street East, Suite : 202 <br />
                Ancaster, Ontario L9G 2C1
              </h4>
            </a>
          </div>
          <div className="contact_option-item">
            <a href="tel:+19056485550" rel="noreferrer" target='_blank'>
              <AiOutlinePhone className='contact_option-icon' />
              <h4>+1 (905)648-5554</h4>
            </a>
          </div>
          <div className="contact_option-item">
            <a href="mailto:primecarephysiotherapy@gmail.com" rel="noreferrer" target='_blank'>
              <AiOutlineMail className='contact_option-icon' />
              <h4>primecarephysiotherapy@gmail.com</h4>
            </a>
          </div>

          {/* SOCIALS DIV */}
          <div className="socials">
            <a href="https://www.instagram.com/primecarephysio/" rel="noreferrer" target='_blank'><AiFillInstagram /></a>
            {/* <a href="https://wa.me/+19056485554" rel="noreferrer" target='_blank'><RiWhatsappFill /></a> */}
            <a href="https://www.facebook.com/people/Prime-care-physiotherapy/100063578126184/?paipv=0&eav=AfaXi8MYXqD2q_ahGxAKB9Jj-HFdQzXLq8uSCu5zqQ_JmFIChIlWGNwlTZszPkN8kzA&_rdr" rel="noreferrer" target='_blank'><FaFacebookF /></a>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <input type="number" name='phone' placeholder='Your Phone Number' required />
          <textarea name="message" rows="7" placeholder='Your Message'></textarea>
          <button type='submit' className='btn btn-primary' value='Send'>Send Message</button>
        </form>
        {/* END OF CONTACT OPTIONS */}

        {/* </div> */}
      </section>
    </>
  )
}

export default Contact